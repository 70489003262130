@import "https://use.typekit.net/zkw6bql.css";

:root {
  --swiper-theme-color: #007aff;
  --primary-color: #ff5722;
  --secondary-color: #e0e0e0;
  --text-color: #333;
  --header-bg: #fff;
  --border-color: #e0e0e0;
  --highlight-color: #f60;
}

html,
body {
  color: var(--text-color);
  font-family: pragmatica-extended, sans-serif;
  font-size: 16px;
  height: 100%;
  margin: 0;
  overflow-y: auto;
  padding: 0;
  scroll-behavior: smooth;
}

input,
select,
textarea {
  width: 80%;
  box-sizing: border-box;
}
