@import "https://use.typekit.net/zkw6bql.css";

.main-header {
  background-color: var(--header-bg);
  width: 100%;
  display: flex;
  border-bottom: 1px solid var(--border-color);
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.page-title {
  font-size: 1.25rem;
  line-height: 30px;
  margin-right: 1rem;
  text-align: right;
  text-wrap: pretty;
  font-family: termina, sans-serif;
  font-weight: 700;
}

.logo {
  padding-bottom: 1rem;
  padding-top: 1rem;
}

.logo img {
  height: auto;
  max-width: 144px;
}

@media (min-width: 640px) {
  .logo img {
    max-width: 166px;
  }
}

@media (min-width: 1024px) {
  .logo img {
    max-width: 166px;
  }
}
