@import "https://use.typekit.net/zkw6bql.css";

.form-container {
  margin: 0 auto 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.thank-you-message,
.error-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  text-align: center;
}

.error-message {
  color: rgb(239 68 68);
}

.ankieta-uczestnikow {
  width: 100%;
  max-width: 700px;
}
