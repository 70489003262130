@import "https://use.typekit.net/zkw6bql.css";
@import "~cropperjs/dist/cropper.css";

#profile_visibility {
  width: 60px;
  accent-color: rgb(255 87 34);
}

label[for="profile_visibility"] {
  display: inline;
}

.form-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.form-page h2 {
  font-size: 1rem;
  line-height: 30px;
  text-wrap: pretty;
  font-family: termina, sans-serif;
  font-weight: 700;
  margin: 0;
}

.button-group {
  display: flex;
  margin-top: 20px;
}

.button {
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-family: "Pragmatica-Extended", sans-serif;
  font-size: 16px;
  padding: 14px 28px;
  transition: background-color 0.3s;
}

.button--primary {
  background-color: var(--primary-color);
  color: #fff;
  flex-grow: 1;
  margin-left: 10px;
  max-width: 300px;
}

.button--primary--disabled {
  background-color: var(--secondary-color);
  color: var(--text-color);
  flex-grow: 1;
  margin-left: 10px;
  max-width: 300px;
  cursor: auto;
}

.button--secondary {
  background-color: var(--secondary-color);
  color: var(--text-color);
  font-size: 14px;
  padding: 10px 20px;
}

.button--primary:hover {
  background-color: #e64a19;
}

.button--secondary:hover {
  background-color: #bdbdbd;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.form-group .checkbox-wrapper,
.form-group .radio-wrapper {
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: 300;
  gap: 0.5rem;
  line-height: 1.4;
}

.form-group .checkbox-wrapper input,
.form-group .radio-wrapper input {
  --tw-text-opacity: 1;
  color: rgb(255 87 34);
  cursor: pointer;
}

.form-group .checkbox-wrapper label,
.form-group .radio-wrapper label {
  font-size: 12px;
  line-height: 1.4;
  padding-top: 5px;
}

.cropper-modal-content {
  margin: 0.5rem;
}

.cropper-container-img {
  max-height: 80dvh;
}

.cropper-img {
  max-width: 100%;
  max-height: 100%;
}

.cropper-container-button {
  margin-top: 10px;
  display: flex;
}

@media (min-width: 1024px) {
  .cropper-container-img {
    max-height: 80vh;
  }
}
@media (min-width: 500px) {
  #profile_visibility {
    width: 45px;
  }
}

@media (min-width: 640px) {
  .form-group input,
  .form-group select {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@media (min-width: 500px) {
  #profile_visibility {
    width: 35px;
  }
}
