.page-container {
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  max-width: 1600px;
  min-height: 100vh;

  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

.page-content {
  margin-top: 20px;
  flex-grow: 1;
}

.error-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  text-align: center;
}

.error-message {
  color: rgb(239 68 68);
}

@media (min-width: 640px) {
  .page-container {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
}

@media (min-width: 1024px) {
  .page-container {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}
