@import "https://use.typekit.net/zkw6bql.css";

.footer {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--header-bg);
  border-top: 1px solid var(--border-color);
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  width: 100%;
  max-width: 1200px;

  padding: 10px 0;
}

.footer a {
  color: #000;
  font-family: "Pragmatica-Extended", sans-serif;
  font-weight: 700;
  text-decoration: none;
}

.footer-logo img {
  height: 30px;
}

.footer-info {
  display: flex;
  gap: 20px;
  font-size: 12px;
}

.footer-credits {
  font-size: 12px;
  display: flex;
  align-items: center;
}

.footer-credits img {
  height: 15px;
  margin-left: 10px;
}

.footer-content {
  text-align: center;
}

.footer-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.footer-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media (min-width: 1024px) {
  .footer-content {
    flex-direction: row;
  }
}
