@import "https://use.typekit.net/zkw6bql.css";
@import "~cropperjs/dist/cropper.css";

.form-group label {
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 21px;
}

.form-control {
  width: 100%;
}

.form-group {
  position: relative;
}

.form-group.has-error .input-container:has(:not(input[type="checkbox"])):after {
  content: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12 8.45v4M12 21a9 9 0 1 1 0-18 9 9 0 0 1 0 18Zm.05-5.55v.1h-.1v-.1h.1Z' stroke='%23ef4444' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  margin: 0.8rem;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 28%;
  transform: translateY(-50%);
  transition: opacity 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  visibility: visible;
}

.form-group .input-container:after {
  opacity: 0;
  visibility: hidden;
}

.form-group .input-container:has(:not(input[type="checkbox"])) {
  position: relative;
  width: 100%;
}

input[type="text" i] {
  padding-block: 1px;
  padding-inline: 2px;
}
input:not(
    [type="image" i],
    [type="range" i],
    [type="checkbox" i],
    [type="radio" i]
  ) {
  overflow-clip-margin: 0px !important;
  overflow: clip !important;
}

* {
  border: 0 solid #e5e7eb;
  box-sizing: border-box;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;

  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  padding: 0;
}

[multiple],
[type="date"],
[type="datetime-local"],
[type="email"],
[type="month"],
[type="number"],
[type="password"],
[type="search"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"],
[type="week"],
select,
textarea {
  --tw-shadow: 0 0 #0000;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border-color: #6b7280;
  border-radius: 0;
  border-width: 1px;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0.5rem 0.75rem;
}

.form-group input,
.form-group select {
  --tw-border-opacity: 1;
  border-color: rgb(207 216 220 / var(--tw-border-opacity));
  border-radius: 0.5rem;
  border-width: 1px;
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
  padding: 0.75rem;
  position: relative;
}

.form-group input,
.form-group select {
  transition-duration: 0.2s;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    -webkit-backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

.form-group .input-container:has(:not(input[type="checkbox"])) input {
  width: 100%;
}

.form-group .validation-response {
  --tw-text-opacity: 1;
  border-radius: 0.5rem;
  color: rgb(239 68 68);
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  line-height: 18px;
  transition-duration: 0.2s;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  background-color: rgb(254 242 242);
  color: rgb(239 68 68);
  margin-top: 0.75rem;
  padding: 0.75rem;
  visibility: visible;
}
